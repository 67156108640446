<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-2">
      <h2 class="text-lg font-medium mr-auto">Follow Up Delegation Board</h2>
    </div>
    <div class="intro-y grid grid-cols-7 mt-2">
      <div id="app-status-board" class="box px-2 py-2 mr-2 col-span-5">
        <div
          class="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4 ml-4"
        >
          <div id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
            <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <label class="w-3/4 flex-none xl:flex-initial mr-2"
                >Agent Name</label
              >
              <input
                id="tabulator-html-filter-value"
                v-model="agentNameForFilter"
                class="form-control w-full mt-2 sm:mt-0"
                placeholder="Search..."
                type="text"
                @keyup.enter="submitAgentFilter"
              />
            </div>
            <div class="mt-2 xl:mt-0">
              <button
                id="tabulator-html-filter-go"
                class="btn btn-success w-full sm:w-16"
                type="button"
                @click="submitAgentFilter"
              >
                Go
              </button>
              <button
                id="tabulator-html-filter-reset"
                class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                type="button"
                @click="clearAgentFilter"
              >
                Reset
              </button>
            </div>
          </div>
          <!-- BEGIN: Clear Selection Switcher-->
          <div
            v-if="selectedAssignedLoanFollowUps.length > 0"
            class="flex mt-5 sm:mt-0"
          >
            <button
              class="btn btn-rounded bg-theme-15 w-1/2 sm:w-auto mr-2"
              @click="emptySelectedAssignedFollowUpsBag"
            >
              <XCircleIcon class="w-4 h-4 mr-2" />
              Clear Assigned Selections
            </button>
            <button
              class="btn btn-rounded bg-theme-11 w-1/2 sm:w-auto mr-2"
              @click="submitAssignedFollowUpsForUnassignment"
            >
              <UserXIcon class="w-4 h-4 mr-2" />
              Unassign
              {{ selectedAssignedLoanFollowUps.length }} Selections
            </button>
          </div>
          <div class="flex mt-5 sm:mt-0">
            <button
              v-if="selectedFollowUpAgent > 0"
              id="tabulator-print"
              class="btn btn-rounded bg-theme-15 w-1/2 sm:w-auto mr-2"
              @click="resetFollowUpAgentSelection"
            >
              <XCircleIcon class="w-4 h-4 mr-2" />
              Clear Selection
            </button>
          </div>
          <!-- END: Clear Selection Switcher-->
        </div>
        <div class="overflow-y-scroll" style="max-height: 84vh">
          <table class="table lending-tbl border-collapse">
            <thead>
              <tr class="bg-theme-13">
                <th class="border dark:border-dark-5" rowspan="2">
                  Agents
                </th>
                <th class="border dark:border-dark-5" colspan="3">
                  Loan Applications
                </th>
              </tr>
              <tr class="bg-theme-13">
                <th class="border dark:border-dark-5">
                  New
                </th>
                <th class="border dark:border-dark-5">
                  In Progress
                </th>
                <th class="border dark:border-dark-5">
                  Contact Failed
                </th>
              </tr>
            </thead>
            <tbody v-if="!fetchingFollowUpAgents">
              <tr
                v-for="(lendingAgent, lendingAgentKey) in followUpAgents"
                :key="lendingAgentKey"
                :class="{
                  'bg-green-100': selectedFollowUpAgent === lendingAgent.id
                }"
              >
                <td
                  class="border"
                  @click="setSelectedFollowUpAgent(lendingAgent.id)"
                >
                  <div class="font-medium text-left">
                    <div class="ml-3">
                      {{ lendingAgent.fullname }} <br />
                      <div
                        v-if="lendingAgent.logged_in_today"
                        class="text-theme-1"
                      >
                        <CheckCircleIcon class="w-3 h-3" />
                        Active
                      </div>
                      <div v-else class="text-theme-13">
                        <MinusCircleIcon class="w-3 h-3" />
                        Inactive
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  v-for="(invFollowUp,
                  invFollowUpKey) in lendingAgent.involved_follow_ups_as_per_status"
                  :key="invFollowUpKey"
                  class="border brd-cl-assigned"
                >
                  <table
                    v-if="invFollowUp.length > 0"
                    class="table ln-assess-tbl"
                  >
                    <thead>
                      <tr>
                        <th class="border dark:border-dark-5">
                          Name
                        </th>
                        <th class="border dark:border-dark-5">
                          Amount
                        </th>
                        <th class="border dark:border-dark-5">
                          Wages
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(singleFollowUpAssignment,
                        singleFollowUpAssignmentKey) in invFollowUp"
                        :key="singleFollowUpAssignmentKey"
                      >
                        <td
                          class="border"
                          @click="
                            toggleInsertionToFollowUpUnAssignmentSelectionBag(
                              singleFollowUpAssignment.uuid
                            )
                          "
                        >
                          <CheckCircleIcon
                            v-if="
                              selectedAssignedLoanFollowUps.includes(
                                singleFollowUpAssignment.uuid
                              )
                            "
                            class="w-4 h-4 float-left text-theme-11"
                          />
                          {{ singleFollowUpAssignment.loan.customer.firstname }}
                          {{
                            singleFollowUpAssignment.loan.customer.middlename ??
                              singleFollowUpAssignment.loan.customer.middlename
                          }}
                          {{
                            singleFollowUpAssignment.loan.customer.lastname ??
                              singleFollowUpAssignment.loan.customer.lastname
                          }}
                        </td>
                        <td class="border">
                          {{ singleFollowUpAssignment.loan.total_amount }}
                        </td>
                        <td class="border">
                          {{ singleFollowUpAssignment.total_income }}
                          <a
                            class="ml-2 underline text-blue-600 hover:cursor-pointer"
                            target="_blank"
                            @click.prevent="
                              onClickResolveAppDetailsAndReRoute(
                                singleFollowUpAssignment.application_id
                              )
                            "
                            >View</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            <FetchingRecordTblTemplate v-else />
          </table>
        </div>
      </div>
      <div id="unassigned-app-list" class="box px-2 py-2 col-span-2">
        <div class="mb-2 grid grid-rows-2">
          <div>
            <div class="form-check">
              <div class="dark-mode-switcher">
                <div
                  :class="{
                    'dark-mode-switcher__toggle--active':
                      selectedUnassignedLoanFollowUps.length ===
                      unassignedLoanFollowUps.length
                  }"
                  class="dark-mode-switcher__toggle border"
                  @click="selectAllUnassigned"
                ></div>
              </div>
              <label class="form-check-label">
                Select All
              </label>
            </div>
          </div>
          <div>
            <button
              v-if="selectedUnassignedLoanFollowUps.length > 0"
              class="btn btn-sm btn-rounded bg-theme-3 text-white w-auto mr-2"
              type="button"
              @click="assignSelectedUnassigned"
            >
              <CheckCircleIcon class="w-3 h-3 mr-1" />
              Assign
            </button>
            <button
              v-if="selectedUnassignedLoanFollowUps.length > 0"
              class="btn btn-sm btn-rounded bg-theme-15 text-white w-auto mr-2"
              @click="emptySelectedUnassignedFollowUpsBag"
            >
              <XCircleIcon class="w-3 h-3 mr-1" />
              Clear
            </button>
          </div>
        </div>
        <div class="overflow-y-scroll" style="max-height: 84vh">
          <table class="table lending-tbl">
            <thead>
              <tr class="bg-theme-13">
                <th class="border dark:border-dark-5" colspan="3">
                  Un Assigned
                </th>
              </tr>
            </thead>
            <tbody v-if="!fetchingUnassignedLoanFollowUps">
              <tr>
                <td class="border">
                  <table class="table ln-assess-tbl">
                    <thead>
                      <tr>
                        <th
                          v-if="selectedFollowUpAgent"
                          class="border dark:border-dark-5"
                        ></th>
                        <th class="border dark:border-dark-5">
                          Name
                        </th>
                        <th class="border dark:border-dark-5">
                          Amount
                        </th>
                        <th class="border dark:border-dark-5">
                          Wages
                        </th>
                        <th class="border dark:border-dark-5">
                          Type
                        </th>
                        <th class="border dark:border-dark-5">
                          Requested time
                        </th>
                        <th class="border dark:border-dark-5">
                          Contact Failed
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(unLnFollowUp,
                        unLnFollowUpKey) in unassignedLoanFollowUps"
                        :key="unLnFollowUpKey"
                        :class="{
                          'bg-gray-200 dark:bg-dark-1': selectedUnassignedLoanFollowUps.includes(
                            unLnFollowUp.uuid
                          )
                        }"
                      >
                        <td
                          v-if="selectedFollowUpAgent"
                          class="border-collapse bg-theme-5"
                          @click="
                            toggleInsertionToFollowUpAssignmentSelectionBag(
                              unLnFollowUp.uuid
                            )
                          "
                        >
                          <CircleIcon
                            v-if="
                              !selectedUnassignedLoanFollowUps.includes(
                                unLnFollowUp.uuid
                              )
                            "
                            class="w-4 h-4"
                          />
                          <CheckCircleIcon
                            v-if="
                              selectedUnassignedLoanFollowUps.includes(
                                unLnFollowUp.uuid
                              )
                            "
                            class="w-4 h-4"
                          />
                        </td>
                        <td
                          class="border"
                          @click="
                            toggleInsertionToFollowUpAssignmentSelectionBag(
                              unLnFollowUp.uuid
                            )
                          "
                        >
                          {{ unLnFollowUp.fullname }}
                          <br v-if="unLnFollowUp.status === 'Contact Failed'" />
                          <i
                            v-if="unLnFollowUp.status === 'Contact Failed'"
                            class="text-theme-11"
                            >(Contact Failed)</i
                          >
                        </td>
                        <td class="border">
                          {{ unLnFollowUp.total_amount }}
                        </td>
                        <td class="border">
                          {{ unLnFollowUp.total_income }}
                          <a
                            class="ml-2 underline text-blue-600 hover:cursor-pointer"
                            target="_blank"
                            @click.prevent="
                              onClickResolveAppDetailsAndReRoute(
                                unLnFollowUp.application_id
                              )
                            "
                            >View</a
                          >
                        </td>
                        <td class="border">
                          {{ unLnFollowUp.follow_up_type_slug }}
                          <div
                            v-if="unLnFollowUp.status === 'Approved'"
                            class="whitespace-nowrap bg-theme-16 hover:bg-theme-16 px-2 ml-2 mr-2 text-white h-5 w-14"
                          >
                            {{ unLnFollowUp.status }}
                          </div>
                        </td>
                        <td class="border">
                          {{ unLnFollowUp.last_follow_up_at }}
                        </td>
                        <td class="border">
                          {{ unLnFollowUp.contact_failed_cnt }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            <FetchingRecordTblTemplate v-else />
          </table>
        </div>
      </div>
    </div>
    <FundoLmsToastAlert />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { customerFollowUpDelegationBoard } from "@/composables/customerFollowUpDelegationBoard";
import FundoLmsToastAlert from "@/components/fundo-lms-toast-alert/Main";
import FetchingRecordTblTemplate from "@/components/fetching-record-tbl-template/Main";

export default defineComponent({
  components: {
    FundoLmsToastAlert,
    FetchingRecordTblTemplate
  },
  setup() {
    const {
      adminUser,
      agentNameForFilter,
      clearAgentFilter,
      submitAgentFilter,
      fetchingFollowUpAgents,
      followUpAgents,
      unassignedLoanFollowUps,
      fetchingUnassignedLoanFollowUps,
      selectedUnassignedLoanFollowUps,
      toggleInsertionToFollowUpAssignmentSelectionBag,
      removeFromSelectedUnassignedLoanFollowUps,
      emptySelectedUnassignedFollowUpsBag,
      selectAllUnassigned,
      assignSelectedUnassigned,
      selectedAssignedLoanFollowUps,
      onClickResolveAppDetailsAndReRoute,
      toggleInsertionToFollowUpUnAssignmentSelectionBag,
      removeFromSelectedAssignedLoanFollowUps,
      emptySelectedAssignedFollowUpsBag,
      submitAssignedFollowUpsForUnassignment,
      selectedFollowUpAgent,
      setSelectedFollowUpAgent,
      resetFollowUpAgentSelection,
      busy,
      sideMenuCollapsed
    } = customerFollowUpDelegationBoard();

    return {
      adminUser,
      agentNameForFilter,
      clearAgentFilter,
      submitAgentFilter,
      fetchingFollowUpAgents,
      followUpAgents,
      unassignedLoanFollowUps,
      fetchingUnassignedLoanFollowUps,
      selectedUnassignedLoanFollowUps,
      onClickResolveAppDetailsAndReRoute,
      toggleInsertionToFollowUpAssignmentSelectionBag,
      removeFromSelectedUnassignedLoanFollowUps,
      emptySelectedUnassignedFollowUpsBag,
      submitAssignedFollowUpsForUnassignment,
      selectAllUnassigned,
      assignSelectedUnassigned,
      selectedAssignedLoanFollowUps,
      toggleInsertionToFollowUpUnAssignmentSelectionBag,
      removeFromSelectedAssignedLoanFollowUps,
      emptySelectedAssignedFollowUpsBag,
      selectedFollowUpAgent,
      setSelectedFollowUpAgent,
      resetFollowUpAgentSelection,
      busy,
      sideMenuCollapsed
    };
  }
});
</script>
<style>
.lending-tbl td {
  padding: 0 !important;
  text-align: center;
  font-size: smaller;
}

.lending-tbl th {
  padding: 0 !important;
  text-align: center;
  font-size: smaller;
}

.ln-assess-tbl {
  border: 2px solid #1e2533;
}

.ln-assess-tbl th {
  background: #9fa8bb;
}
</style>
